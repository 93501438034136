// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Press+Start+2P&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bard-back-text {\n    position: absolute;\n    z-index: 11;\n    color: whitesmoke;\n    font-family: 'Press Start 2P', cursive;\n    left: 50%;\n    transform: translateX(-50%);\n    text-align: center;\n    /* background-color: red; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    bottom: 28%;\n    font-size: 1.45em;\n    text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);\n    line-height: 1.2em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/gamePageElements/CardBack.css"],"names":[],"mappings":"AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,sCAAsC;IACtC,SAAS;IACT,2BAA2B;IAC3B,kBAAkB;IAClB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,yCAAyC;IACzC,kBAAkB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Press+Start+2P&display=swap');\n\n\n.bard-back-text {\n    position: absolute;\n    z-index: 11;\n    color: whitesmoke;\n    font-family: 'Press Start 2P', cursive;\n    left: 50%;\n    transform: translateX(-50%);\n    text-align: center;\n    /* background-color: red; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    bottom: 28%;\n    font-size: 1.45em;\n    text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);\n    line-height: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
