// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Press+Start+2P&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".choose-boss-wrapper {\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.311);\n    z-index: 15;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.choose-boss-holder {\n    display: flex;\n    gap: 30px;\n    align-items: center;\n    justify-content: center;\n}\n\n.choose-boss {\n    cursor: pointer;\n    transition-duration: 0.3s;\n}\n\n.choose-boss:hover {\n    scale: 1.05;\n}\n\n.choose-boss-header {\n    color: whitesmoke;\n    font-family: 'Press Start 2P', cursive;\n    font-size: 25px;\n    text-shadow: 2px 2px 5px rgb(38, 38, 38);\n}\n", "",{"version":3,"sources":["webpack://./src/components/gamePageElements/ChooseBoss.css"],"names":[],"mappings":"AAIA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,sCAAsC;IACtC,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,sCAAsC;IACtC,eAAe;IACf,wCAAwC;AAC5C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;600;700&family=Press+Start+2P&display=swap');\n\n\n\n.choose-boss-wrapper {\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.311);\n    z-index: 15;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.choose-boss-holder {\n    display: flex;\n    gap: 30px;\n    align-items: center;\n    justify-content: center;\n}\n\n.choose-boss {\n    cursor: pointer;\n    transition-duration: 0.3s;\n}\n\n.choose-boss:hover {\n    scale: 1.05;\n}\n\n.choose-boss-header {\n    color: whitesmoke;\n    font-family: 'Press Start 2P', cursive;\n    font-size: 25px;\n    text-shadow: 2px 2px 5px rgb(38, 38, 38);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
